import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { ReactComponent as IconConfig } from '../../assets/notifications/icon-configuration.svg'
import { useMst } from '../../stores/store'
import type { Option } from '../admin/forms'
import ContentPage from '../dataroom/content-page'
import { Input, selectStyles } from '../shared/select-styles'
import Notification from './notification'
import { NOTIFICATION_NAMESPACE, type NotificationNamespace } from './notifications.types'

interface NotificationSelectOption {
    value: NotificationNamespace | ''
    label: string
}

const Notifications = observer(() => {
    const rootStore = useMst()
    const { t } = useTranslation()
    const { notificationCenter } = rootStore

    const options: NotificationSelectOption[] = [
        { value: '', label: t('web_notifications_ns_all') },
        { value: NOTIFICATION_NAMESPACE.TELECOLLECTE, label: t('web_notifications_ns_telecollecte') },
        { value: NOTIFICATION_NAMESPACE.OCR, label: t('web_notifications_ns_ocr') },
        { value: NOTIFICATION_NAMESPACE.SHARING, label: t('web_notifications_ns_sharing') },
        { value: NOTIFICATION_NAMESPACE.TRANSFER, label: t('web_notifications_ns_transfer') },
        {
            value: NOTIFICATION_NAMESPACE.COLLABORATION_MESSAGES,
            label: t('web_notifications_ns_collaboration_messages'),
        },
        { value: NOTIFICATION_NAMESPACE.TREASY, label: t('web_notifications_ns_treasy') },
        { value: NOTIFICATION_NAMESPACE.SPONSORING, label: t('web_notifications_ns_sponsoring') },
        { value: NOTIFICATION_NAMESPACE.SECURITY, label: t('web_notifications_ns_security') },
    ]

    const [selectedNotificationOption, setSelectedNotificationOption] = useState<Option>({ value: null, label: null })
    const [sortByDate, setSortByDate] = useState<'ASC' | 'DESC'>('DESC')

    useEffect(() => {
        const load = async () => {
            await notificationCenter.load(
                selectedNotificationOption.value === '' || selectedNotificationOption.value === null
                    ? undefined
                    : selectedNotificationOption.value,
                sortByDate
            )
            await notificationCenter.markAsRead()
        }
        load()
    }, [selectedNotificationOption, sortByDate])

    return (
        <ContentPage title={t('web_notifications_title')}>
            <div className="flex w-full flex-col space-y-2">
                <div className="flex items-center space-x-2">
                    <span>
                        <Trans
                            i18nKey="web_notifications_new_notifications"
                            values={{ count: notificationCenter.notifications.length }}
                        >
                            web_notifications_new_notifications
                        </Trans>
                    </span>
                    <Link to="/me/notifications/settings">
                        <IconConfig className="cursor-pointer fill-current text-regent-gray hover:animate-spin" />
                    </Link>
                    <div className="flex w-3/6 flex-col items-start space-y-2 px-6 py-1 pt-px md:flex-row md:space-x-8 md:space-y-0">
                        <Select
                            styles={selectStyles}
                            components={{ Input }}
                            className="w-full md:w-1/2"
                            value={selectedNotificationOption}
                            placeholder={t('web_placeholder_select')}
                            options={options}
                            onChange={type => setSelectedNotificationOption(type)}
                        />
                    </div>
                    <div className="flex items-center space-x-2 ">
                        <span>
                            <Trans i18nKey="web_me_notification_sort_by_date" values={{}}>
                                web_notifications_sort_by_date
                            </Trans>
                        </span>
                        {sortByDate === 'DESC' ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => setSortByDate('ASC')}
                                className="h-4 w-4 cursor-pointer text-atomic-tangerine"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => setSortByDate('DESC')}
                                className="h-4 w-4 cursor-pointer text-atomic-tangerine"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                            </svg>
                        )}
                    </div>
                </div>
                {notificationCenter.notifications.length === 0 && (
                    <div className="text-regent-gray">{t('web_notifications_no_notification')}</div>
                )}
                {notificationCenter.notifications.map(notification => (
                    <Notification key={notification.id} notification={notification} />
                ))}
            </div>
        </ContentPage>
    )
})

export default Notifications
