import { faCog, faPlug, faUsers, faWallet } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as IconAdmin } from 'assets/icons/backoffice.svg'
import BrandsIcon from 'assets/icons/backoffice/brands'
import FranchiseIcon from 'assets/icons/backoffice/franchise'
import NotificationIcon from 'assets/icons/backoffice/notification'
import PartnersIcon from 'assets/icons/backoffice/partners'
import PricingIcon from 'assets/icons/backoffice/pricing'
import UsersIcon from 'assets/icons/backoffice/users'
import { ReactComponent as IconAdd } from 'assets/icons/icon-add-file.svg'
import { ReactComponent as IconAddTrusted } from 'assets/icons/icon-add-trusted.svg'
import { ReactComponent as IconDataroom } from 'assets/icons/icon-dataroom.svg'
import { ReactComponent as IconHistoric } from 'assets/icons/icon-historic.svg'
import { ReactComponent as IconHome } from 'assets/icons/icon-home.svg'
import { ReactComponent as IconMessage } from 'assets/icons/icon-message.svg'
import { ReactComponent as NewsIcon } from 'assets/icons/icon-news.svg'
import { ReactComponent as IconPartner } from 'assets/icons/icon-partner.svg'
import { ReactComponent as IconQuarantine } from 'assets/icons/icon-quarantine.svg'
import { ReactComponent as IconShare } from 'assets/icons/icon-share.svg'
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg'
import CasesIcon from 'assets/icons/partners/cases'
import IconEmail from 'assets/icons/partners/email'
import largeLogo from 'assets/large-logo.svg'
import logo from 'assets/logo.svg'
import { ReactComponent as Plus } from 'assets/plus.svg'
import ViaTreasy from 'assets/via-treasy'
import { clsx } from 'clsx'
import { AppContext } from 'context'
import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { hasBrandAccess } from 'stores/brands'
import type { Space } from 'stores/files/space'
import { useMst } from 'stores/store'
import { MenuDivider, MenuItem } from '../shared/menu'
import { MenuLink, Separator, SubMenu } from '../shared/menu/menulink'

interface Props {
    className?: string
    onAddFileClicked?: () => void
    onAddFindPartnerClicked?: () => void
    onAddTrustedPersonClicked?: () => void
}

const Menu = observer(
    ({ className = '', onAddFileClicked, onAddFindPartnerClicked, onAddTrustedPersonClicked }: Props) => {
        const { t } = useTranslation()
        const rootStore = useMst()
        const { menuOpen: isMenuOpen, isLogged, user, files } = rootStore
        const [menuOpen, setMenuOpen] = useState<boolean>(isMenuOpen)

        const [isVisible, setVisible] = useState<boolean>(false)
        const popRef = useRef<HTMLDivElement>(null)

        const { logo: contextLogo } = useContext(AppContext)

        const handleHideDropdown = useCallback((event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setVisible(false)
            }
        }, [])

        const handleClickOutside = useCallback(
            (event: Event) => {
                if (popRef.current && !popRef.current.contains(event.target as Node)) {
                    setVisible(false)
                }
            },
            [popRef]
        )

        useEffect(() => {
            document.addEventListener('keydown', handleHideDropdown, true)
            document.addEventListener('click', handleClickOutside, true)

            return () => {
                document.removeEventListener('keydown', handleHideDropdown, true)
                document.removeEventListener('click', handleClickOutside, true)
            }
        })

        const toggleMenu = () => {
            const toggle = !menuOpen
            setMenuOpen(toggle)
            rootStore.setMenuOpen(toggle)
        }

        const getLogo = (logo?: string) => {
            if (logo) {
                return logo
            }

            let logoSource: { logo: string; icon: string } = {
                logo: user.currentFranchise.logo,
                icon: user.currentFranchise.icon,
            }

            if (user.hasPartner) {
                logoSource = { logo: user.partner.logo, icon: user.partner.icon }
            }

            if (menuOpen) {
                return logoSource.logo
            }

            return logoSource.icon
        }

        const getOfferedBy = () => {
            if (user.hasPartner) {
                return user.partner.name
            }

            if (user.hasFranchise) {
                return user.currentFranchise.name
            }

            if (user.currentBrand) {
                return user.currentBrand.name
            }

            return null
        }

        return (
            <div
                className={clsx(
                    className,
                    'relative hidden transition-all duration-500 ease-in-out md:flex print:hidden',
                    menuOpen ? 'w-64' : 'w-16'
                )}
                style={{ minWidth: menuOpen ? '16rem' : '4rem' }}
            >
                <div className="flex size-full flex-col justify-between bg-white shadow-lg">
                    <div>
                        <Link to="/dashboard">
                            {user.hasPartner || user.hasFranchise || contextLogo ? (
                                <div
                                    className={clsx('flex flex-wrap items-center justify-between', {
                                        'p-4': menuOpen,
                                        'p-1': !menuOpen,
                                    })}
                                >
                                    <div className="flex grow justify-start">
                                        <img
                                            src={getLogo(contextLogo)}
                                            className="m-auto max-h-20"
                                            alt={user.hasPartner ? user.partner.name : user.currentFranchise.name}
                                        />
                                    </div>
                                    <div className="flex grow justify-end">
                                        <ViaTreasy className="text-[#2c2a2c]" />
                                    </div>
                                </div>
                            ) : (
                                <img src={menuOpen ? largeLogo : logo} className="m-auto p-4" alt="Treasy" />
                            )}
                        </Link>
                    </div>

                    {isLogged && (
                        <>
                            {user.canManagePersonalData && (
                                <div
                                    className={clsx(
                                        className,
                                        'relative m-4 inline-block w-2/3 cursor-pointer rounded-full bg-white shadow-lg'
                                    )}
                                >
                                    <div
                                        onClick={() => setVisible(true)}
                                        className={clsx(
                                            'flex h-12 flex-wrap items-center',
                                            menuOpen ? 'px-4' : 'justify-center'
                                        )}
                                    >
                                        <Plus
                                            title={t('web_dataroom_add')}
                                            className={clsx('text-christine stroke-current', { 'mr-4': menuOpen })}
                                        />
                                        {menuOpen && (
                                            <span className="text-heather font-bold">{t('web_dataroom_add')}</span>
                                        )}
                                    </div>
                                    <div ref={popRef}>
                                        {isVisible && (
                                            <div
                                                className={
                                                    'absolute top-0 z-50 float-left mt-1 flex w-80 origin-bottom flex-col rounded-lg bg-white text-left text-base shadow-lg'
                                                }
                                            >
                                                {onAddFileClicked ? (
                                                    <span
                                                        className="text-regent-gray inline-flex items-center px-4 py-2"
                                                        onClick={onAddFileClicked}
                                                    >
                                                        <IconAdd className="mr-2 size-4 fill-current" />
                                                        <span>{t('web_menu_add_files')}</span>
                                                    </span>
                                                ) : (
                                                    <MenuItem
                                                        to="/dataroom"
                                                        icon={<IconAdd className="mr-2 size-4 fill-current" />}
                                                    >
                                                        {t('web_menu_add_files')}
                                                    </MenuItem>
                                                )}
                                                {hasBrandAccess(user, 'show_telecollecte') && (
                                                    <>
                                                        <MenuDivider />
                                                        <MenuItem
                                                            to="/dataroom/telecollecte"
                                                            icon={
                                                                <FontAwesomeIcon
                                                                    icon={faPlug}
                                                                    className="mr-2 size-4 fill-current"
                                                                />
                                                            }
                                                        >
                                                            {t('web_menu_add_telecollecte')}
                                                        </MenuItem>
                                                        <span
                                                            className="text-regent-gray inline-flex items-center px-4 py-2"
                                                            onClick={onAddTrustedPersonClicked}
                                                        >
                                                            <IconPartner className="mr-2 size-4 fill-current" />
                                                            <span>{t('web_menu_add_trusted_person')}</span>
                                                        </span>
                                                        <span
                                                            className="text-regent-gray inline-flex items-center px-4 py-2"
                                                            onClick={onAddFindPartnerClicked}
                                                        >
                                                            <IconAddTrusted className="mr-2 size-4 fill-current" />
                                                            <span>{t('web_menu_find_partner')}</span>
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            <ul className="w-full grow truncate">
                                <MenuLink
                                    link="/dashboard"
                                    label="web_link_dashboard"
                                    icon={
                                        <IconHome
                                            className="mr-2 inline-block fill-current"
                                            title={t('web_link_dashboard')}
                                        />
                                    }
                                />

                                {user.canManagePersonalData && (
                                    <MenuLink
                                        link="/dataroom"
                                        label="web_link_dataroom"
                                        icon={
                                            <IconDataroom
                                                className="mr-2 inline-block fill-current"
                                                title={t('web_link_dataroom')}
                                            />
                                        }
                                    />
                                )}
                                {values<Space>(files.spaces)
                                    .filter(
                                        space =>
                                            (space.userUuid === user.uuid &&
                                                space.jsonOptions?.displayOn?.user === 'menu') ||
                                            (space.userUuid !== user.uuid &&
                                                space.jsonOptions?.displayOn?.others === 'menu')
                                    )
                                    .map(space => (
                                        <MenuLink
                                            key={space.uuid}
                                            link={`/dataroom/spaces/${space.uuid}`}
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={faWallet}
                                                    className="mr-2 inline-block fill-current"
                                                />
                                            }
                                            label={space.displayNameForUser(user)}
                                        />
                                    ))}

                                {user.canManagePersonalData && (
                                    <>
                                        <MenuLink
                                            link="/collaboration"
                                            label="web_link_collaboration"
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={faUsers}
                                                    className="mr-2 inline-block fill-current"
                                                    title={t('web_link_collaboration')}
                                                />
                                            }
                                        />

                                        <MenuLink
                                            link="/share"
                                            label="web_link_share"
                                            icon={
                                                <IconShare
                                                    className="mr-2 inline-block fill-current"
                                                    title={t('web_link_share')}
                                                />
                                            }
                                        />

                                        {hasBrandAccess(user, 'show_telecollecte') && (
                                            <MenuLink
                                                link="/dataroom/telecollecte"
                                                label="web_link_telecollecte"
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={faPlug}
                                                        className="mr-2 inline-block fill-current"
                                                        title={t('web_link_telecollecte')}
                                                    />
                                                }
                                            />
                                        )}

                                        {false && (
                                            <MenuLink
                                                link="/dataroom/quarantine"
                                                label="web_link_quarantine"
                                                icon={
                                                    <IconQuarantine
                                                        className="mr-2 inline-block fill-current"
                                                        title={t('web_link_quarantine')}
                                                    />
                                                }
                                            />
                                        )}

                                        {false && (
                                            <MenuLink
                                                link="/dataroom/historic"
                                                label="web_link_historic"
                                                icon={
                                                    <IconHistoric
                                                        className="mr-2 inline-block fill-current"
                                                        title={t('web_link_historic')}
                                                    />
                                                }
                                            />
                                        )}

                                        <MenuLink
                                            link="/dataroom/trash"
                                            label="web_link_trash"
                                            icon={
                                                <IconTrash
                                                    className="mr-2 inline-block fill-current"
                                                    title={t('web_link_trash')}
                                                />
                                            }
                                        />
                                    </>
                                )}

                                {user.isAdmin && (
                                    <>
                                        <Separator />

                                        <SubMenu
                                            name="admin"
                                            label="web_admin"
                                            icon={
                                                <IconAdmin
                                                    className="mr-2 inline-block size-4 fill-current"
                                                    title={t('web_link_dashboard')}
                                                />
                                            }
                                        >
                                            <MenuLink
                                                link="/admin/settings"
                                                label="web_admin_settings"
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={faCog}
                                                        className="mr-2 inline-block size-4 fill-current"
                                                    />
                                                }
                                                inSubMenu={true}
                                            />
                                            <MenuLink
                                                link="/admin/users"
                                                label="web_admin_users"
                                                icon={
                                                    <UsersIcon
                                                        className="mr-2 inline-block size-4 fill-current"
                                                        title={t('web_admin_users')}
                                                    />
                                                }
                                                inSubMenu={true}
                                            />
                                            <MenuLink
                                                link="/admin/user-types"
                                                label="web_admin_user_types"
                                                icon={
                                                    <UsersIcon
                                                        className="mr-2 inline-block size-4 fill-current"
                                                        title={t('web_admin_users')}
                                                    />
                                                }
                                                inSubMenu={true}
                                            />
                                            <MenuLink
                                                link="/admin/partners"
                                                label="web_admin_partners"
                                                icon={
                                                    <PartnersIcon
                                                        className="mr-2 inline-block size-4 fill-current"
                                                        title={t('web_admin_partners')}
                                                    />
                                                }
                                                inSubMenu={true}
                                            />
                                            <MenuLink
                                                link="/admin/brands"
                                                label="web_admin_brands"
                                                icon={
                                                    <BrandsIcon
                                                        className="mr-2 inline-block size-4 fill-current"
                                                        title={t('web_admin_brands')}
                                                    />
                                                }
                                                inSubMenu={true}
                                            />
                                            <MenuLink
                                                link="/admin/pricings"
                                                label="web_admin_pricings"
                                                icon={
                                                    <PricingIcon
                                                        className="mr-2 inline-block size-4 stroke-current"
                                                        title={t('web_admin_pricings')}
                                                    />
                                                }
                                                inSubMenu={true}
                                            />
                                            <MenuLink
                                                link="/admin/notification"
                                                label="web_admin_notification"
                                                icon={
                                                    <NotificationIcon
                                                        className="mr-2 inline-block size-4 stroke-current"
                                                        title={t('web_admin_notification')}
                                                    />
                                                }
                                                inSubMenu={true}
                                            />
                                            <MenuLink
                                                link="/admin/information-messages"
                                                label="web_admin_information_messages"
                                                icon={
                                                    <IconMessage
                                                        className="mr-2 inline-block size-4 fill-current"
                                                        title={t('web_admin_information_messages')}
                                                    />
                                                }
                                                inSubMenu={true}
                                            />
                                            {/* <MenuLink
                                            link={`/admin/welcome-message`}
                                            label="web_partner_welcome_message"
                                            icon={
                                                <IconMessage
                                                    className="fill-current mr-2 inline-block w-4 h-4"
                                                    title={t('web_partner_welcome_message')}
                                                />
                                            }
                                            inSubMenu={true}
                                        /> */}
                                            <MenuLink
                                                link="/admin/cgu"
                                                label="web_admin_cgu"
                                                icon={
                                                    <IconAdd
                                                        className="mr-2 inline-block size-4 fill-current"
                                                        title={t('web_admin_cgu')}
                                                    />
                                                }
                                                inSubMenu={true}
                                            />
                                        </SubMenu>
                                    </>
                                )}

                                {user.managingPartners.map(partner => (
                                    <Fragment key={partner.uuid}>
                                        <Separator />
                                        <SubMenu
                                            name={partner.uuid}
                                            label={t('web_menu_admin_tag', { name: partner.name })}
                                            icon={
                                                <img
                                                    src={partner.icon}
                                                    className="mr-2 inline-block size-4 stroke-current"
                                                    title={partner.name}
                                                />
                                            }
                                        >
                                            {partner.isManager && (
                                                <>
                                                    <MenuLink
                                                        link={`/partner/${partner.uuid}/news`}
                                                        label="web_partner_news"
                                                        icon={
                                                            <NewsIcon
                                                                className="mr-2 inline-block size-4 fill-current"
                                                                title={t('web_partner_news')}
                                                            />
                                                        }
                                                        inSubMenu={true}
                                                    />
                                                    <MenuLink
                                                        link={`/partner/${partner.uuid}/welcome-message`}
                                                        label="web_partner_welcome_message"
                                                        icon={
                                                            <IconMessage
                                                                className="mr-2 inline-block size-4 fill-current"
                                                                title={t('web_partner_welcome_message')}
                                                            />
                                                        }
                                                        inSubMenu={true}
                                                    />
                                                    <MenuLink
                                                        link={`/partner/${partner.uuid}/email`}
                                                        label="web_partner_email"
                                                        icon={
                                                            <IconEmail
                                                                className="mr-2 inline-block size-4 stroke-current"
                                                                title={t('web_partner_email')}
                                                            />
                                                        }
                                                        inSubMenu={true}
                                                    />
                                                    <MenuLink
                                                        link={`/partner/${partner.uuid}/notification`}
                                                        label="web_admin_notification"
                                                        icon={
                                                            <NotificationIcon
                                                                className="mr-2 inline-block size-4 stroke-current"
                                                                title={t('web_admin_notification')}
                                                            />
                                                        }
                                                        inSubMenu={true}
                                                    />
                                                    <MenuLink
                                                        link={`/partner/${partner.uuid}/messages`}
                                                        label="web_admin_information_messages"
                                                        icon={
                                                            <IconMessage
                                                                className="mr-2 inline-block size-4 fill-current"
                                                                title={t('web_admin_information_messages')}
                                                            />
                                                        }
                                                        inSubMenu={true}
                                                    />
                                                    <MenuLink
                                                        link={`/partner/${partner.uuid}/users`}
                                                        label="web_admin_users"
                                                        icon={
                                                            <UsersIcon
                                                                className="mr-2 inline-block size-4 fill-current"
                                                                title={t('web_admin_users')}
                                                            />
                                                        }
                                                        inSubMenu={true}
                                                    />
                                                </>
                                            )}
                                            <MenuLink
                                                link={`/partner/${partner.uuid}/cases`}
                                                label="web_partner_my_cases"
                                                icon={
                                                    <CasesIcon
                                                        className="mr-2 inline-block size-4 stroke-current"
                                                        title={t('web_partner_my_cases')}
                                                    />
                                                }
                                                inSubMenu={true}
                                            />
                                        </SubMenu>
                                    </Fragment>
                                ))}

                                {user.brands
                                    .filter(brand => ['CONTROLLER', 'ADMIN'].includes(brand.role))
                                    .map(brand => (
                                        <Fragment key={brand.uuid}>
                                            <Separator />
                                            <SubMenu
                                                name={brand.uuid}
                                                label={brand.name}
                                                icon={
                                                    <img
                                                        src={brand.icon}
                                                        className="mr-2 inline-block size-4 stroke-current"
                                                        title={brand.name}
                                                    />
                                                }
                                            >
                                                <MenuLink
                                                    link={`/brands/${brand.uuid}/news`}
                                                    label="web_partner_news"
                                                    icon={
                                                        <NewsIcon
                                                            className="mr-2 inline-block size-4 fill-current"
                                                            title={t('web_partner_news')}
                                                        />
                                                    }
                                                    inSubMenu={true}
                                                />
                                                <MenuLink
                                                    link={`/brands/${brand.uuid}/welcome-message`}
                                                    label="web_partner_welcome_message"
                                                    icon={
                                                        <IconMessage
                                                            className="mr-2 inline-block size-4 fill-current"
                                                            title={t('web_partner_welcome_message')}
                                                        />
                                                    }
                                                    inSubMenu={true}
                                                />
                                                <MenuLink
                                                    link={`/brands/${brand.uuid}/franchises`}
                                                    label={brand.getConfig().franchise_label ?? 'web_admin_franchises'}
                                                    icon={<FranchiseIcon className="mr-2 size-5" />}
                                                    inSubMenu={true}
                                                />
                                                <MenuLink
                                                    link={`/brands/${brand.uuid}/messages`}
                                                    label="web_admin_information_messages"
                                                    icon={
                                                        <IconMessage
                                                            className="mr-2 inline-block size-4 fill-current"
                                                            title={t('web_admin_information_messages')}
                                                        />
                                                    }
                                                    inSubMenu={true}
                                                />
                                            </SubMenu>
                                        </Fragment>
                                    ))}

                                {user.franchises
                                    .filter(franchise => ['FRANCHISEE'].includes(franchise.role))
                                    .map(franchise => (
                                        <Fragment key={franchise.uuid}>
                                            <Separator />
                                            <SubMenu
                                                name={franchise.uuid}
                                                label={franchise.name}
                                                icon={
                                                    <img
                                                        src={franchise.icon}
                                                        className="mr-2 inline-block size-4 stroke-current"
                                                        title={franchise.name}
                                                    />
                                                }
                                            >
                                                <MenuLink
                                                    link={`/franchises/${franchise.uuid}/users`}
                                                    label="web_admin_users"
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={faUsers}
                                                            className="mr-2 inline-block size-4 fill-current"
                                                            title={t('web_admin_users')}
                                                        />
                                                    }
                                                    inSubMenu={true}
                                                />
                                            </SubMenu>
                                        </Fragment>
                                    ))}
                            </ul>

                            {menuOpen && (
                                <div className="text-regent-gray mb-28 flex w-full flex-col px-4 py-2 text-center text-sm">
                                    <span className="italic">
                                        <Trans i18nKey="web_footer_treasy">
                                            <span className="font-bold">Treasy</span> votre
                                        </Trans>
                                    </span>

                                    {getOfferedBy() !== null && (
                                        <span className="font-bold">
                                            {t('web_footer_treasy_partner', { partner: getOfferedBy() })}
                                        </span>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
                {isLogged && (
                    <div
                        className="menu-switch text-heather absolute flex h-12 w-4 cursor-pointer items-center justify-center rounded bg-white shadow-lg"
                        onClick={toggleMenu}
                    >
                        {menuOpen ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="size-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="size-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                        )}
                    </div>
                )}
            </div>
        )
    }
)

export default Menu
