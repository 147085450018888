import { faUserSecret } from '@fortawesome/pro-regular-svg-icons'
import { faUsers } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import Tippy from '@tippyjs/react'
import { ReactComponent as ArrowRight } from 'assets/arrow/arrow-right.svg'
import IconSpace from 'assets/icons/icon-space'
import ContentPage from 'components/dataroom/content-page'
import Breadcrumb from 'components/shared/breadcrumb'
import Modal from 'components/shared/modal'
import Panel from 'components/shared/panel'
import SmallLoader from 'components/shared/small-loader'
import ToggleButton from 'components/shared/toggle-button'
import Tooltip from 'components/shared/tooltip'
import { AppContext } from 'context'
import { get } from 'core/services/http-service'
import { mapOrJsonOptions, mapToObject } from 'core/utils/map-to-object'
import toast from 'core/utils/toast'
import { DateTime } from 'luxon'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { type CSSProperties, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import type { MultiValue } from 'react-select'
import type { Space } from 'stores/files/space'
import { useMst } from 'stores/store'
import type { User } from 'stores/users'
import SpaceroomMenu from '../dataroom/spaceroom-menu'
import LineUser from './line-user'
import LineUsers from './line-users'
import { UsersDropdown } from './users-dropdown'

interface Collaboration {
    uuid: string
    createdAt: string
    updatedAt: string
    deletedAt: string | null
    space: Space
    users: User[]
}

interface CollaborationSpaceProps {
    collaboration: Collaboration
    onRefresh: () => void
    mine: boolean
}

interface Data {
    spaces: Collaboration[]
}

const InfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.655 23.31" className="size-5">
        <path
            data-name="Icon open-info"
            d="M8.741 0a2.914 2.914 0 1 0 2.914 2.914A2.922 2.922 0 0 0 8.741 0Zm-4.37 7.284A4.365 4.365 0 0 0 0 11.655h2.914a1.457 1.457 0 1 1 2.914 0c0 .816-2.914 4.779-2.914 7.284a4.31 4.31 0 0 0 4.37 4.371 4.365 4.365 0 0 0 4.371-4.371H8.741a1.457 1.457 0 0 1-2.914 0c0-1.049 2.914-5.361 2.914-7.284a4.4 4.4 0 0 0-4.37-4.371Z"
            fill="#8391a0"
        />
    </svg>
)

const CollaborationSpace = ({
    mine,
    collaboration: { space, users, deletedAt },
    onRefresh,
}: CollaborationSpaceProps) => {
    const { user, files } = useMst()
    const { t } = useTranslation()
    const { logo: contextLogo } = useContext(AppContext)

    const getLogo = (logo?: string) => {
        if (logo) {
            return logo
        }

        let logoSource: { logo: string; icon: string } = {
            logo: user.currentFranchise.logo,
            icon: user.currentFranchise.icon,
        }

        if (user.hasPartner) {
            logoSource = { logo: user.partner.logo, icon: user.partner.icon }
        }

        return logoSource.icon
    }

    const [showSpaceroomMenu, setShowSpaceroomMenu] = useState<boolean>(false)

    const secureCollaboration = !!(space.options?.secure_collaboration ?? false)

    return (
        <div className="relative flex items-start rounded p-4 shadow-lg">
            <div className="absolute right-2 top-1 flex items-center gap-1">
                <Tooltip tooltip={space.description ?? t('web_link_collaboration_tooltip')}>
                    <div className="flex size-8 items-center justify-center rounded-full bg-white shadow-md">
                        <InfoIcon />
                    </div>
                </Tooltip>

                <LineUser
                    user={mine ? user : { uuid: space.userUuid, fullname: space.username, picture: space.userPicture }}
                    zIndex={1}
                />
                <div
                    onClick={() => setShowSpaceroomMenu(true)}
                    className="relative flex w-12 cursor-pointer items-center justify-between rounded bg-white p-2 shadow"
                >
                    <div className="rounded-50 size-3 text-right shadow-md" style={{ backgroundColor: space.color }} />
                    <ArrowRight className="text-regent-gray size-2 fill-current" />
                </div>
                <SpaceroomMenu
                    space={space}
                    isVisible={showSpaceroomMenu}
                    onClose={() => setShowSpaceroomMenu(false)}
                    onRefresh={() => {
                        files.markForceRefresh(space.uuid)
                        onRefresh()
                    }}
                    onDeleted={() => {
                        files.markForceRefresh(space.uuid)
                        onRefresh()
                    }}
                    isShared={true}
                    mine={mine}
                />
            </div>

            <IconSpace
                isLogo={getLogo(contextLogo)}
                isShared={true}
                isSecure={secureCollaboration}
                className="w-10 flex-none fill-current "
                style={{ color: space.color }}
            />

            <div
                className="text-thunder -mt-1 ml-4 flex flex-col"
                style={{
                    width: 'calc(100% - 2.5rem - 0.25rem - 6.5rem)',
                }}
            >
                <Link to={`/dataroom/spaces/${space.uuid}`} className="flex w-56 flex-row items-center">
                    {secureCollaboration && (
                        <Tippy content={t('web_collaboration_confidential')}>
                            <FontAwesomeIcon icon={faUserSecret} className="mr-2" />
                        </Tippy>
                    )}

                    <span className="truncate text-lg">{space.name}</span>
                </Link>
                <div className="border-b-geyser flex items-center justify-start gap-3 border-b pb-2 text-sm font-semibold">
                    <LineUsers users={users} size="sm" />
                    <div>
                        <span>{t('web_collaboration_people', { count: users.length })}</span>&nbsp;-&nbsp;
                        <span>{t('web_collaboration_file', { count: space.totalFiles })}</span>
                    </div>
                </div>
                {deletedAt && (
                    <div className="border-b-geyser flex items-center justify-start gap-3 border-b py-2 text-sm font-semibold">
                        <span className="text-christine">
                            {t(
                                space.userUuid === user.uuid && mine
                                    ? 'web_collaboration_deleted_you'
                                    : users.find(u => u.uuid === user.uuid)?.deletedBy === user.uuid
                                      ? 'web_collaboration_left'
                                      : 'web_collaboration_deleted',
                                {
                                    name: space.username,
                                    date: DateTime.fromISO(deletedAt)
                                        .plus({ days: 7 })
                                        .toLocaleString(DateTime.DATETIME_MED),
                                }
                            )}
                        </span>
                    </div>
                )}
                <div
                    className="mt-2 h-12 w-full truncate whitespace-pre-wrap"
                    style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' } as CSSProperties}
                >
                    {space.description}
                </div>
            </div>
        </div>
    )
}

export const NewCollaboration = ({
    isOpen,
    onClose,
    onRefresh,
    space,
}: {
    isOpen: boolean
    onClose: () => void
    onRefresh: () => void
    space?: Space
}) => {
    const { t } = useTranslation()
    const { user, files } = useMst()
    const navigate = useNavigate()

    const config = mapOrJsonOptions(toJS(user.currentBrand?.config ?? user.currentFranchise?.brandConfig ?? {}))
    const canSecure = !!(config.secure_collaboration ?? false)

    type Option = { value?: string; label: string; options?: Option[] }

    const [spaceName, setSpaceName] = useState<string>('')
    const [color, setColor] = useState<string>()
    const [description, setDescription] = useState<string>('')
    const [selectedPeople, setSelectedPeople] = useState<MultiValue<Option>>([])
    const [isSecure, setIsSecure] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)

    const close = () => {
        setSpaceName('')
        setColor(undefined)
        setDescription('')
        setIsSecure(false)
        setSelectedPeople([])
        onClose()
    }

    const onCreateSpace = async () => {
        setSubmitting(true)
        const sharedWith = selectedPeople.map(({ value }) => value)

        if (space) {
            await space.update(space.name, space.color, null, description, sharedWith, isSecure)
        } else {
            await files.createSpace(spaceName, color, null, description, sharedWith, isSecure)
        }

        toast('success', t('web_collaboration_create_success'))

        if (space) {
            navigate('/collaboration')
        }

        setSubmitting(false)
        onRefresh()
        close()
    }

    const submitDisabled = () => {
        if (selectedPeople.length === 0) {
            return true
        }
        if (space) {
            return false
        }

        return spaceName === '' || !color || submitting
    }

    useEffect(() => {
        files.getConfig()
        if (space) {
            setDescription(space.description)
        }
    }, [])

    return (
        <Modal
            overflowHidden={false}
            size="1/2"
            isOpen={isOpen}
            onRequestClose={() => {}}
            title={
                <div className="flex w-full items-center justify-start gap-4">
                    <div className="bg-christine flex aspect-square items-center justify-center rounded-full p-1 text-sm">
                        <FontAwesomeIcon icon={faUsers} fixedWidth={true} className="text-white" />
                    </div>
                    <h4 className="font-nunito text-center text-xl font-bold">{t('web_collaboration_create_title')}</h4>
                </div>
            }
        >
            <div className="mb-1 mt-2 flex w-full flex-col">
                <div className="mb-8 flex w-full flex-col gap-4">
                    {space ? (
                        <>
                            {canSecure && (
                                <div className="my-4 flex gap-4">
                                    <span className="text-regent-gray">{t('web_collaboration_edit_space_secure')}</span>
                                    <ToggleButton value={isSecure} onChange={secure => setIsSecure(secure)} />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <input
                                type="text"
                                className="w-full"
                                placeholder={t('web_collaboration_create_name')}
                                value={spaceName}
                                onChange={e => setSpaceName(e.target.value)}
                            />
                            {canSecure && (
                                <div className="my-4 flex gap-4">
                                    <span className="text-regent-gray">{t('web_collaboration_edit_space_secure')}</span>
                                    <ToggleButton value={isSecure} onChange={secure => setIsSecure(secure)} />
                                </div>
                            )}
                            <div>
                                <span className="text-regent-gray mb-2">{t('web_create_space_color_help')}</span>
                                <div className="flex gap-4">
                                    {files.colors.map(c => (
                                        <button
                                            key={c}
                                            type="button"
                                            className="relative size-6 rounded-full border-2 outline-none ring-0 transition-all hover:scale-150"
                                            style={{
                                                backgroundColor: c === color ? 'white' : c,
                                                borderColor: c,
                                            }}
                                            onClick={() => setColor(c)}
                                        />
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                    <textarea
                        className="w-full"
                        rows={6}
                        placeholder={t('web_collaboration_create_description')}
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                    <UsersDropdown
                        selectedPeople={selectedPeople}
                        setSelectedPeople={setSelectedPeople}
                        space={space}
                    />
                </div>
                <div className="flex items-center justify-center space-x-4">
                    <button type="button" className="btn white" disabled={submitting} onClick={() => close()}>
                        {t('web_collaboration_create_cancel')}
                    </button>
                    <button type="submit" className="btn" disabled={submitDisabled()} onClick={() => onCreateSpace()}>
                        {t('web_collaboration_create_save')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

const CollaborationPanel = ({ mine }: { mine: boolean }) => {
    const { t } = useTranslation()
    const [showCreate, setShowCreate] = useState<boolean>(false)

    const fetchData = async () => {
        const {
            data: { spaces },
        } = await get<never, { data: Data }>(mine ? '/v1/web/collaboration/mine' : '/v1/web/collaboration/with-me')

        return spaces
            .map(collaboration => {
                let options = collaboration.space.options
                if (options) {
                    if (typeof options === 'string') {
                        options = JSON.parse(options)
                    } else if (typeof options === 'object') {
                        options = mapToObject(options)
                    }
                }

                return {
                    ...collaboration,
                    space: {
                        ...collaboration.space,
                        options,
                    },
                }
            })
            .filter(collaboration => {
                if (!mine) {
                    return true
                }
                if (collaboration.space.options && collaboration.space.options.displayOn?.user === 'dataroom') {
                    return false
                }

                return true
            })
    }

    const { refetch, isLoading, data } = useQuery({
        queryKey: [`collaboration.${mine ? 'mine' : 'with-me'}.index`],
        queryFn: fetchData,
    })

    return (
        <Panel>
            <div className="flex w-full flex-row items-center justify-between">
                <span className="text-lg">
                    <Trans i18nKey={mine ? 'web_collaboration_mine' : 'web_collaboration_with_me'}>
                        {mine ? 'web_collaboration_mine' : 'web_collaboration_with_me'}
                    </Trans>
                </span>
                {mine && (
                    <>
                        <button
                            className="text-atomic-tangerine cursor-pointer"
                            type="button"
                            onClick={() => setShowCreate(true)}
                        >
                            {t('web_collaboration_add_new')}
                        </button>
                        <NewCollaboration
                            isOpen={showCreate}
                            onClose={() => setShowCreate(false)}
                            onRefresh={() => refetch()}
                        />
                    </>
                )}
            </div>

            {isLoading ? (
                <SmallLoader />
            ) : data?.length > 0 ? (
                <div className="mt-4 grid w-full grid-cols-2 gap-4 xl:grid-cols-3 ">
                    {data?.map(collaboration => (
                        <CollaborationSpace
                            key={collaboration.uuid}
                            mine={mine}
                            collaboration={collaboration}
                            onRefresh={() => refetch()}
                        />
                    ))}
                </div>
            ) : (
                <span className="text-sm font-semibold">
                    {t(`web_collaboration_no_spaces_${mine ? 'mine' : 'with_me'}`)}
                </span>
            )}
        </Panel>
    )
}

const Collaboration = observer(() => {
    const { t } = useTranslation()

    const { files } = useMst()

    useEffect(() => {
        const load = async () => {
            await files.getConfig()
            await files.getFiles()
        }
        load()
    }, [files])

    return (
        <ContentPage title={t('web_collaboration_page_title')} canDrag={true}>
            <Breadcrumb>
                <Link to="/dataroom">{t('web_breadcrumb_dataroom')}</Link>
                <div className="flex items-center justify-center space-x-2">
                    {/*<IconSpace className="w-5 flex-none fill-current" style={{ color: space.color }} />*/}
                    <span>{t('web_collaboration_page_title')}</span>
                </div>
            </Breadcrumb>

            <div className="flex w-full flex-col gap-6">
                <CollaborationPanel mine={true} />
                <CollaborationPanel mine={false} />
            </div>
        </ContentPage>
    )
})

export default Collaboration
