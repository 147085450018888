import { selectStyles } from 'components/shared/select-styles'
import { observer } from 'mobx-react-lite'
import { type ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import type { FirebaseBlacklist } from 'stores/users'
import { ReactComponent as IconEmail } from '../../assets/notifications/icon-email.svg'
import { ReactComponent as IconPush } from '../../assets/notifications/icon-push.svg'
import { ReactComponent as IconSms } from '../../assets/notifications/icon-sms.svg'
import { useMst } from '../../stores/store'
import ToggleButton from '../shared/toggle-button'
import {
    NOTIFICATION_CHANNEL,
    NOTIFICATION_FREQUENCY,
    type NotificationChannel,
    type NotificationFrequency,
    type NotificationNamespace,
} from './notifications.types'

interface ToggleProps {
    icon: ReactElement
    isLoading: boolean
    label: string
    value: boolean
    onChange: (value: boolean) => void
}

const Toggle = ({ isLoading, value, onChange, icon, label }: ToggleProps) => {
    return (
        <div className="flex items-center space-x-2 text-inner-space">
            {icon}
            <span className="w-32">{label}</span>
            {isLoading ? (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 h-4 w-4 animate-spin"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                </svg>
            ) : (
                <ToggleButton value={value} onChange={onChange} />
            )}
        </div>
    )
}

interface FormSelectValue {
    value: string
    label: string
}
interface DropdownProps {
    icon: ReactElement
    isLoading: boolean
    label: string
    value: NotificationFrequency
    onChange: (value: FormSelectValue) => void
}

const FrequencyDropdown = ({ isLoading, value, onChange, icon, label }: DropdownProps) => {
    const { t } = useTranslation()

    const options = Object.values(NOTIFICATION_FREQUENCY).map(frequency => ({
        value: frequency,
        label: t(`web_notifications_frequency_${frequency}`),
    }))

    return (
        <div className="flex items-center space-x-2 text-inner-space">
            {icon}
            <span className="w-32">{label}</span>
            {isLoading ? (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 h-4 w-4 animate-spin"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                </svg>
            ) : (
                <Select
                    styles={selectStyles}
                    className="w-1/2"
                    value={options.find(opt => opt.value === value)}
                    options={options}
                    onChange={newValue => onChange(newValue)}
                />
            )}
        </div>
    )
}

const canSendSms = false
const canSendPush = true
const canSendInApp = true
const canSendEmail = true

export interface NotificationSettingProps {
    namespace: NotificationNamespace
    icon: ReactElement
}

const NotificationSetting = observer(({ namespace, icon }: NotificationSettingProps) => {
    const { t } = useTranslation()
    const { user } = useMst()

    const [toggle, setToggle] = useState<boolean>(false)
    const [activatedChannelList, setActivatedChannelList] = useState<NotificationChannel[]>([])

    const [inApp, setInApp] = useState<NotificationFrequency>(NOTIFICATION_FREQUENCY.LIVE)
    const [inAppLoading, setInAppLoading] = useState<boolean>(false)

    const [email, setEmail] = useState<NotificationFrequency>(NOTIFICATION_FREQUENCY.LIVE)
    const [emailLoading, setEmailLoading] = useState<boolean>(true)

    const [push, setPush] = useState<NotificationFrequency>(NOTIFICATION_FREQUENCY.LIVE)
    const [pushLoading, setPushLoading] = useState<boolean>(true)

    const [sms, setSms] = useState<NotificationFrequency>(NOTIFICATION_FREQUENCY.NONE)
    const [smsLoading, setSmsLoading] = useState<boolean>(true)

    useEffect(() => {
        const newActivatedChannelList: NotificationChannel[] = []

        const firebaseBlacklist: FirebaseBlacklist[] = user.firebaseBlacklist

        if (canSendEmail) {
            const email = firebaseBlacklist.find(
                fb => fb.namespace === namespace && fb.channel === NOTIFICATION_CHANNEL.EMAIL
            )
            setEmail(email ? (email.frequency as NotificationFrequency) : NOTIFICATION_FREQUENCY.NONE)
            if (email && (email.frequency as NotificationFrequency) !== NOTIFICATION_FREQUENCY.NONE) {
                newActivatedChannelList.push(NOTIFICATION_CHANNEL.EMAIL)
            }
        }

        if (canSendInApp) {
            const inapp = firebaseBlacklist.find(
                fb => fb.namespace === namespace && fb.channel === NOTIFICATION_CHANNEL.IN_APP
            )
            setInApp(inapp ? (inapp.frequency as NotificationFrequency) : NOTIFICATION_FREQUENCY.NONE)
            if (inapp && (inapp.frequency as NotificationFrequency) !== NOTIFICATION_FREQUENCY.NONE) {
                newActivatedChannelList.push(NOTIFICATION_CHANNEL.IN_APP)
            }
        }

        if (canSendPush) {
            const push = firebaseBlacklist.find(
                fb => fb.namespace === namespace && fb.channel === NOTIFICATION_CHANNEL.PUSH
            )
            setPush(push ? (push.frequency as NotificationFrequency) : NOTIFICATION_FREQUENCY.NONE)
            if (push && (push.frequency as NotificationFrequency) !== NOTIFICATION_FREQUENCY.NONE) {
                newActivatedChannelList.push(NOTIFICATION_CHANNEL.PUSH)
            }
        }

        if (canSendSms) {
            const sms = firebaseBlacklist.find(
                fb => fb.namespace === namespace && fb.channel === NOTIFICATION_CHANNEL.SMS
            )
            setSms(sms ? (sms.frequency as NotificationFrequency) : NOTIFICATION_FREQUENCY.NONE)
            if (sms && (sms.frequency as NotificationFrequency) !== NOTIFICATION_FREQUENCY.NONE) {
                newActivatedChannelList.push(NOTIFICATION_CHANNEL.SMS)
            }
        }

        setActivatedChannelList(newActivatedChannelList)
        setPushLoading(false)
        setEmailLoading(false)
        setInAppLoading(false)
        setSmsLoading(false)
    }, [user])

    const toggleInApp = async (value: boolean) => {
        setInAppLoading(true)
        await user.updateNotification(
            namespace,
            NOTIFICATION_CHANNEL.IN_APP,
            value ? NOTIFICATION_FREQUENCY.LIVE : NOTIFICATION_FREQUENCY.NONE
        )
        setInApp(value ? NOTIFICATION_FREQUENCY.LIVE : NOTIFICATION_FREQUENCY.NONE)
        const newActivatedChannelList = activatedChannelList
        if (value) {
            newActivatedChannelList.push(NOTIFICATION_CHANNEL.IN_APP)
        } else {
            newActivatedChannelList.splice(newActivatedChannelList.indexOf(NOTIFICATION_CHANNEL.IN_APP), 1)
        }

        setInAppLoading(false)
    }

    const toggleEmail = async (value: FormSelectValue) => {
        setEmailLoading(true)
        await user.updateNotification(namespace, NOTIFICATION_CHANNEL.EMAIL, value.value as NotificationFrequency)
        setEmail(value.value as NotificationFrequency)
        const newActivatedChannelList = activatedChannelList
        if ((value.value as NotificationFrequency) !== NOTIFICATION_FREQUENCY.NONE) {
            if (newActivatedChannelList.indexOf(NOTIFICATION_CHANNEL.EMAIL) < 0) {
                newActivatedChannelList.push(NOTIFICATION_CHANNEL.EMAIL)
            }
        } else {
            newActivatedChannelList.splice(newActivatedChannelList.indexOf(NOTIFICATION_CHANNEL.EMAIL), 1)
        }

        setEmailLoading(false)
    }

    const togglePush = async (value: boolean) => {
        setPushLoading(true)
        await user.updateNotification(
            namespace,
            NOTIFICATION_CHANNEL.PUSH,
            value ? NOTIFICATION_FREQUENCY.LIVE : NOTIFICATION_FREQUENCY.NONE
        )
        setPush(value ? NOTIFICATION_FREQUENCY.LIVE : NOTIFICATION_FREQUENCY.NONE)
        const newActivatedChannelList = activatedChannelList
        if (value) {
            newActivatedChannelList.push(NOTIFICATION_CHANNEL.PUSH)
        } else {
            newActivatedChannelList.splice(newActivatedChannelList.indexOf(NOTIFICATION_CHANNEL.PUSH), 1)
        }

        setPushLoading(false)
    }

    const toggleSms = async (value: boolean) => {
        setSmsLoading(true)
        await user.updateNotification(
            namespace,
            NOTIFICATION_CHANNEL.SMS,
            value ? NOTIFICATION_FREQUENCY.LIVE : NOTIFICATION_FREQUENCY.NONE
        )
        setSms(value ? NOTIFICATION_FREQUENCY.LIVE : NOTIFICATION_FREQUENCY.NONE)
        const newActivatedChannelList = activatedChannelList
        if (value) {
            newActivatedChannelList.push(NOTIFICATION_CHANNEL.SMS)
        } else {
            newActivatedChannelList.splice(newActivatedChannelList.indexOf(NOTIFICATION_CHANNEL.SMS), 1)
        }

        setSmsLoading(false)
    }

    return (
        <div className="flex flex-row items-start space-x-8 border-b border-mercury p-4">
            <div className="pt-2 text-regent-gray">{icon}</div>
            <div className="flex flex-col space-y-2">
                <div className="flex cursor-pointer flex-col" onClick={() => setToggle(!toggle)}>
                    <h4 className="font-bold">{t(`web_notifications_ns_${namespace}`)}</h4>
                    {!toggle && (
                        <span>
                            {activatedChannelList.map(channel => t(`web_notifications_channel_${channel}`)).join(', ')}
                        </span>
                    )}
                    {toggle && <span>{t(`web_notifications_ns_${namespace}_desc`)}</span>}
                </div>
                <div className={`${toggle ? 'flex flex-col space-y-4' : 'hidden'}`}>
                    {canSendEmail && (
                        <FrequencyDropdown
                            isLoading={emailLoading}
                            value={email}
                            onChange={toggleEmail}
                            label={t('web_notifications_channel_email')}
                            icon={<IconEmail className="h-5 w-5 fill-current" />}
                        />
                    )}
                    {canSendInApp && (
                        <Toggle
                            isLoading={inAppLoading}
                            value={inApp && inApp === NOTIFICATION_FREQUENCY.LIVE}
                            onChange={toggleInApp}
                            label={t('web_notifications_channel_inapp')}
                            icon={<IconPush className="h-5 w-5 fill-current" />}
                        />
                    )}
                    {canSendPush && (
                        <Toggle
                            isLoading={pushLoading}
                            value={push && push === NOTIFICATION_FREQUENCY.LIVE}
                            onChange={togglePush}
                            label={t('web_notifications_channel_push')}
                            icon={<IconPush className="h-5 w-5 fill-current" />}
                        />
                    )}
                    {canSendSms && (
                        <Toggle
                            isLoading={smsLoading}
                            value={sms && sms === NOTIFICATION_FREQUENCY.LIVE}
                            onChange={toggleSms}
                            label={t('web_notifications_channel_sms')}
                            icon={<IconSms className="h-5 w-5 fill-current" />}
                        />
                    )}
                </div>
            </div>
        </div>
    )
})

export default NotificationSetting
