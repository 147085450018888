import toast from 'core/utils/toast'
import { flow } from 'mobx'
import { type Instance, getRoot, types } from 'mobx-state-tree'
import { del, get, post, put } from '../core/services/http-service'
import { _Notification } from './notification/notification'
import type { RootInstance } from './store'

export const _NotificationCenter = types
    .model('NotificationCenter', {
        notifications: types.array(_Notification),
    })
    .views(self => ({
        get hasUnreadNotifications(): boolean {
            const notRead = self.notifications.filter(notification => !notification.read)

            return notRead.length > 0
        },
    }))
    .actions(self => ({
        replace: ({ notifications }) => {
            self.notifications = notifications
        },
        removeNotification(id) {
            const selectedNotification = self.notifications.find(notification => (notification.id = String(id)))
            if (selectedNotification) {
                self.notifications.remove(selectedNotification)
            }
        },
    }))
    .actions(self => ({
        load: flow(function* (
            namespaceFilter: string | undefined = undefined,
            sortByDate: 'DESC' | 'ASC' | undefined = undefined
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            type JsonReturnContent = {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const getData = { namespace: namespaceFilter, orderByDate: sortByDate }
                const data = yield get<typeof getData, JsonReturn>('/v1/users/notifications', getData)

                const {
                    data: { notifications },
                } = data
                self.replace({ notifications })
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        notificationDelete: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()
            try {
                yield del<void>(`/v1/users/notifications/${id}`)
                self.removeNotification(id)
                toast('success', 'web_notification_deleted')
            } catch (error) {
                root.error.prepare(error)
            }
        }),

        markAsRead: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield put<void, void>('/v1/users/notifications')
                const notifications = self.notifications.map(notification => {
                    return {
                        ...notification,
                        read: true,
                    }
                })
                self.replace({ notifications })
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        notifyPartners: flow(function* (title: string, message: string, partners: string[]) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = {
                    title,
                    message,
                    partners,
                }

                yield post<typeof postData, void>('/v1/bo/partners/notify', postData)

                toast('success', 'web_admin_notification_sent')
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        notifyUsers: flow(function* (title: string, message: string, tags: string[]) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = {
                    title,
                    message,
                    tags,
                }

                yield post<typeof postData, void>('/v1/bo/users/notify', postData)

                toast('success', 'web_admin_notification_sent')
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        append(notification) {
            self.notifications.push(notification)
        },
    }))

export interface NotificationCenter extends Instance<typeof _NotificationCenter> {}
