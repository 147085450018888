export const NOTIFICATION_NAMESPACE = {
    COLLABORATION_MESSAGES: 'collaboration_messages',
    EMAIL: 'email',
    OCR: 'ocr',
    SECURITY: 'security',
    SHARING: 'sharing',
    SPONSORING: 'sponsoring',
    TELECOLLECTE: 'telecollecte',
    TRANSFER: 'transfer',
    TREASY: 'treasy',
} as const

export type NotificationNamespace = (typeof NOTIFICATION_NAMESPACE)[keyof typeof NOTIFICATION_NAMESPACE]

export const NOTIFICATION_CHANNEL = {
    EMAIL: 'email',
    IN_APP: 'inapp',
    PUSH: 'push',
    SMS: 'sms',
} as const

export type NotificationChannel = (typeof NOTIFICATION_CHANNEL)[keyof typeof NOTIFICATION_CHANNEL]

export const NOTIFICATION_FREQUENCY = {
    LIVE: 'live',
    NONE: 'none',
    ONCE_EVERY_DAY: 'once_every_day',
    ONCE_EVERY_HOUR: 'once_every_hour',
} as const

export type NotificationFrequency = (typeof NOTIFICATION_FREQUENCY)[keyof typeof NOTIFICATION_FREQUENCY]
