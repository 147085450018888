import weTreasyBannerImage from 'assets/file-transfer/banner-we-treasy.webp'
import { ReactComponent as CtaArrowTop } from 'assets/file-transfer/cta-arrow-top.svg'
import Panel from 'components/shared/panel'
import { getWeTreasyUrl } from 'core/utils/get-we-treasy-url'
import { useTranslation } from 'react-i18next'

const weTreasyUrl = getWeTreasyUrl()

export default function WeTreasy() {
    const { t } = useTranslation()

    return (
        <Panel className="welcome" innerClassName="relative bg-white">
            <img src={weTreasyBannerImage} alt="" className="" />
            <div className="justify-between flex items-center w-full pt-6 pb-3">
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('web_file_transfer_dashboard', {
                            url: `<a href="${weTreasyUrl}" target="_blank" class="underline" rel="noreferrer">${weTreasyUrl}</a>`,
                        }),
                    }}
                />

                <a href={weTreasyUrl} target="_blank" className="btn relative whitespace-nowrap" rel="noreferrer">
                    <div className="absolute right-8 bottom-4 hidden lg:block">
                        <CtaArrowTop className="rotate-180" />
                    </div>
                    {t('web_file_transfer_cta_discover_short')}
                </a>
            </div>
        </Panel>
    )
}
