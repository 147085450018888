/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import NoMatch from 'components/dataroom/no-match'
import type { ReactNode } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { useMst } from '../stores/store'
import { getFromQuery } from './use-query'

interface Fc {
    children?: ReactNode
}

export const Redirect = ({ to }: { to: string }) => {
    const params = useParams()

    let url = to
    for (const key of Object.keys(params)) {
        url = url.replace(`:${key}`, params[key])
    }

    window.location.href = url

    return <></>
}

export const GuestRoute = ({ children }: Fc) => {
    const { isLogged } = useMst()
    const location = useLocation()

    if (isLogged) {
        return <Navigate to="/dataroom" state={{ from: location }} />
    }

    return <>{children}</>
}

export const PrivateRoute = ({ children }: Fc) => {
    const { isLogged, logout } = useMst()
    const location = useLocation()

    const forceLogout = Boolean(getFromQuery('logout'))
    if (forceLogout) {
        logout(location.pathname)

        return
    }

    if (!isLogged) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return <>{children}</>
}

export const PersonalDataAccessCheck = ({ children }: Fc) => {
    const { isLogged, user } = useMst()

    if (isLogged && !user.canManagePersonalData) {
        return <NoMatch />
    }

    return <>{children}</>
}

export const AdminRoute = ({ children }: Fc) => {
    const { isLogged, user } = useMst()
    const location = useLocation()

    if (!isLogged || !user.isAdmin) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return <>{children}</>
}

export const PartnerRoute = ({ children }: Fc) => {
    const { isLogged, user } = useMst()
    const location = useLocation()
    const { id } = useParams()

    const hasAccess = isLogged && (user.managingPartners.find(p => p.uuid === id) !== undefined || user.isAdmin)

    if (!hasAccess) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return <>{children}</>
}

export const BrandRoute = ({ children }: Fc) => {
    const { isLogged, user } = useMst()
    const location = useLocation()
    const { id } = useParams()

    const hasAccess = isLogged && (user.brands.find(b => b.uuid === id) !== undefined || user.isAdmin)

    if (!hasAccess) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return <>{children}</>
}

export const BrandFranchiseRoute = ({ children }: Fc) => {
    const { isLogged, user } = useMst()
    const location = useLocation()
    const { id } = useParams()

    const hasAccess = isLogged && (user.franchises.find(f => f.uuid === id) !== undefined || user.isAdmin)

    if (!hasAccess) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return <>{children}</>
}
