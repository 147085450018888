import { AppContext } from 'context'
import { getFromQuery } from 'core/use-query'
import { observer } from 'mobx-react-lite'
import { type KeyboardEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, Navigate, useLocation } from 'react-router-dom'
import hello from '../../assets/login/login-hello.svg'
import security from '../../assets/login/login-security.svg'
import { useMst } from '../../stores/store'
import SecurePassword from '../shared/secure-password'
import LoginApple from './buttons/login-apple'
import LoginGoogle from './buttons/login-google'
import LoginLinkedIn from './buttons/login-linkedin'
import TwoPart from './two-part'

const Login = observer(() => {
    const rootStore = useMst()
    const { user, error } = rootStore
    const { t } = useTranslation()
    const token = getFromQuery('token')
    const sso = getFromQuery('sso')
    const jwtToken = getFromQuery('t')
    const redirect = getFromQuery('redirect')

    const {
        loginRegister: { readOnlyEmail, email },
    } = useContext(AppContext)

    const emailField = useRef<HTMLInputElement>(null)
    const passwordField = useRef<HTMLInputElement>(null)

    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const showTwoFa = rootStore.token !== '' && user.twoFa
    const mainImage = showTwoFa ? security : hello
    const mainDesc = showTwoFa ? 'web_login_2fa_desc' : 'web_login_page_desc'

    const [forceRedirect, setForceRedirect] = useState<boolean>(false)
    const [mustChangePassword, setMustChangePassword] = useState<string>(undefined)

    const [twoFaCode, setTwoFaCode] = useState<string>('')

    const canVerifyCode = (): boolean => twoFaCode !== '' && !isLoading
    const location = useLocation()

    useEffect(() => {
        rootStore.error.clean()
        setErrorMessage(undefined)
        rootStore.reset()
    }, [location])

    const verifyTwoFaCode = async () => {
        setIsLoading(true)

        await user.verifyTwoFaCode(twoFaCode)
        setIsLoading(false)
        if (error.hasError) {
            setErrorMessage(error.message)

            return
        }
        setForceRedirect(true)
    }

    useEffect(() => {
        const loginFromSso = async () => {
            if (sso && sso === 'jwt' && jwtToken) {
                rootStore.setToken(jwtToken)
                await user.jwtConnect()
                await user.linkAccountIfNeeded()
            }
        }

        loginFromSso()
    })

    const login = async () => {
        if (isLoading || !emailField.current || !passwordField.current) {
            return false
        }

        const email = emailField.current.value
        const password = passwordField.current.value

        if (email === '' || password === '') {
            return false
        }

        setIsLoading(true)

        await user.login(email, password, true, undefined, token)
        setIsLoading(false)

        if (error.hasError) {
            setErrorMessage(error.message)

            return false
        }

        if (user.getMustChangePassword()) {
            setMustChangePassword(user.emailToken)

            return false
        }

        await user.linkAccountIfNeeded()

        if (error.hasError) {
            return false
        }

        setForceRedirect(!user.twoFa)

        return false
    }

    const onKeyDown = useCallback(async (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') {
            return
        }
        await login()
    }, [])

    if (rootStore.isLogged || forceRedirect) {
        return <Navigate to={redirect ? redirect : '/dataroom'} />
    }

    if (mustChangePassword) {
        const params = new URLSearchParams({ token: mustChangePassword })

        return <Navigate to={`/change-password?${params.toString()}`} />
    }

    return (
        <TwoPart
            image={mainImage}
            title={t('web_login_page_main_title')}
            subTitle={<Trans i18nKey={mainDesc}>{t('web_login_page_desc')}</Trans>}
            bottomChildren={
                <>
                    <span className="mx-1">{t('web_login_page_connect_sso')}</span>
                    <LoginGoogle />
                    <LoginApple />
                    <LoginLinkedIn />
                </>
            }
        >
            {!showTwoFa && (
                <>
                    <h3 className="my-8 text-center text-2xl font-bold md:text-3xl">{t('web_login_page_title')}</h3>
                    <div className="mx-8 flex w-full flex-col">
                        <label className="uppercase">{t('web_login_page_email_address')}</label>
                        <input
                            type="email"
                            ref={emailField}
                            placeholder={t('web_login_page_email_address')}
                            onKeyDown={e => onKeyDown(e)}
                            value={email}
                            readOnly={readOnlyEmail}
                        />
                        <label className="mt-4 uppercase">{t('web_login_page_password')}</label>
                        <SecurePassword ref={passwordField} onKeyDown={e => onKeyDown(e)} />
                        <div className="mt-4 flex flex-col text-right md:mb-8 md:flex-row-reverse md:justify-between">
                            <Link to="/forgotten-password">{t('web_login_page_forgotten_password')}</Link>
                        </div>
                        {errorMessage && <p className="my-4 text-center text-christine">{t(errorMessage)}</p>}
                        <p className="my-4 mb-7 text-center md:mb-14">
                            <button className="btn px-8" type="button" onClick={() => login()}>
                                {t('web_login_page_continue')}
                            </button>
                        </p>
                        <p className="mb-4 text-center text-lg font-bold md:mb-6">
                            {t('web_login_page_not_have_treasy')}
                        </p>
                        <p className="mb-4 text-center md:mb-11">
                            <Link className="btn px-8" to="/register">
                                {t('web_login_page_register')}
                            </Link>
                        </p>
                    </div>
                </>
            )}
            {showTwoFa && user.twoFaMethod === 'code' && (
                <>
                    <h3 className="my-8 text-2xl font-bold md:text-3xl">{t('web_login_2fa_code_title')}</h3>
                    <p className="mb-8 text-sm text-regent-gray">{t('web_login_2fa_code_help')}</p>

                    <div className="flex w-3/4 flex-col">
                        <label className="uppercase">{t('web_login_2fa_code')}</label>
                        <input
                            type="text"
                            placeholder={t('web_login_2fa_code')}
                            value={twoFaCode}
                            onChange={e => setTwoFaCode(e.target.value)}
                        />
                    </div>

                    {errorMessage && <p className="my-4 text-center text-christine">{t(errorMessage)}</p>}

                    <p className="my-4 text-center">
                        <button className="btn px-8" disabled={!canVerifyCode()} onClick={() => verifyTwoFaCode()}>
                            {t('web_login_page_continue')}
                        </button>
                    </p>
                </>
            )}
        </TwoPart>
    )
})

export default Login
