export interface IconChatProps {
    className?: string
}

export const IconChat = ({ className }: IconChatProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.286 22.286" className={className} fill="currentColor">
            <path
                data-name="Icon feather-message-circle"
                d="M21.036 10.593a9.212 9.212 0 0 1-.986 4.177 9.343 9.343 0 0 1-8.354 5.166 9.212 9.212 0 0 1-4.177-.989L1.25 21.036l2.089-6.266a9.212 9.212 0 0 1-.989-4.177 9.343 9.343 0 0 1 5.166-8.354 9.212 9.212 0 0 1 4.177-.989h.55a9.321 9.321 0 0 1 8.794 8.794Z"
                fill="none"
                stroke="#8391a0"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
            />
        </svg>
    )
}

export default IconChat
