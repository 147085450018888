import Ocr from 'assets/dataroom/ocr'
import IconChat from 'assets/icons/icon-chat'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMst } from 'stores/store'
import { ReactComponent as IconConnector } from '../../assets/connectors.svg'
import { ReactComponent as IconSharing } from '../../assets/notifications/icon-sharing.svg'
import { ReactComponent as IconSponsor } from '../../assets/notifications/icon-sponsor.svg'
import { ReactComponent as IconTransfer } from '../../assets/notifications/icon-transfer.svg'
import { ReactComponent as IconTreasy } from '../../assets/notifications/icon-treasy.svg'
import ContentPage from '../dataroom/content-page'
import Breadcrumb from '../shared/breadcrumb'
import Panel from '../shared/panel'
import NotificationSetting, { type NotificationSettingProps } from './notification-setting'
import { NOTIFICATION_NAMESPACE } from './notifications.types'

const items: NotificationSettingProps[] = [
    { namespace: NOTIFICATION_NAMESPACE.TELECOLLECTE, icon: <IconConnector className="h-6 w-6 fill-current" /> },
    { namespace: NOTIFICATION_NAMESPACE.OCR, icon: <Ocr className="h-6 w-6 fill-current" /> },
    { namespace: NOTIFICATION_NAMESPACE.SHARING, icon: <IconSharing className="h-6 w-6 fill-current" /> },
    { namespace: NOTIFICATION_NAMESPACE.TRANSFER, icon: <IconTransfer className="h-6 w-6 fill-current" /> },
    { namespace: NOTIFICATION_NAMESPACE.COLLABORATION_MESSAGES, icon: <IconChat className="h-6 w-6 fill-current" /> },
    { namespace: NOTIFICATION_NAMESPACE.TREASY, icon: <IconTreasy className="h-6 w-6 fill-current" /> },
    { namespace: NOTIFICATION_NAMESPACE.SPONSORING, icon: <IconSponsor className="h-6 w-6 fill-current" /> },
]

const NotificationSettings = () => {
    const { t } = useTranslation()
    const { user } = useMst()

    useEffect(() => {
        const load = async () => {
            await user.loadNotifications()
        }
        load()
    }, [])

    return (
        <ContentPage title={t('web_notifications_title')}>
            <Breadcrumb>
                <Link to="/me/notifications">{t('web_notifications_title')}</Link>
                <span>{t('web_notifications_configuration_title')}</span>
            </Breadcrumb>

            <div className="mb-4 flex w-full flex-col">
                <span>{t('web_notifications_configuration_subtitle')}</span>
            </div>

            <Panel size="full">
                {items.map(({ namespace, icon }) => (
                    <NotificationSetting key={namespace} namespace={namespace} icon={icon} />
                ))}
            </Panel>
        </ContentPage>
    )
}

export default NotificationSettings
